import React from 'react';
import Layout from './Layout';
import {Container, Row, Col} from 'react-bootstrap';
import AOS from "aos";

const Contact = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration : 1000
          });
    }, []);
    return (
        <Layout>
            <Container>
                <div className="text-center mb-5 pb-md-2">
                    <h1 data-aos="fade-up">Contact Us</h1>
                </div>

                <Row className='mb-5 justify-content-center'>
                    <Col md={4} className='mb-5' data-aos="fade-up" data-aos-delay="200">
                        <h4 className="heading">Email</h4>
                        <address>
                            <h5>FUNDING INQUIRIES</h5>
                            <p>Send us your white papers & investment opportunities at: <a href="mailto:info@mastermind-ventures.com">info@mastermind-ventures.com</a></p>
                        </address>
                    </Col>
                    <Col md={4} className='mb-5' data-aos="fade-up" data-aos-delay="400">
                        <h4 className="heading">Social</h4>

                        <div className="social">
                            {/* <span>
                                <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.56603 3.82127C8.56603 5.93061 6.86938 7.64254 4.77533 7.64254C2.68127 7.64254 0.984626 5.93061 0.984626 3.82127C0.984626 1.71193 2.68127 0 4.77533 0C6.86938 0 8.56603 1.71193 8.56603 3.82127ZM8.5966 10.6996H0.954056V35.1557H8.5966V10.6996ZM20.7941 10.6996H13.1974V35.1557H20.7941V22.3162C20.7941 15.1781 30.011 14.5973 30.011 22.3162V35.1557H37.6383V19.6719C37.6383 7.62726 24.004 8.07052 20.7941 14.0011V10.6996Z" fill="#15182B"></path>
                                </svg>
                            </span>
                            <span>
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 30"><path d="M37.064 3.645a15.109 15.109 0 01-4.326 1.192A7.544 7.544 0 0036.055.68a15.62 15.62 0 01-4.784 1.834 7.468 7.468 0 00-5.488-2.4c-4.86 0-8.422 4.54-7.337 9.248A21.409 21.409 0 012.933 1.49a7.551 7.551 0 002.323 10.057 7.634 7.634 0 01-3.408-.947c-.077 3.485 2.415 6.74 6.037 7.474a7.432 7.432 0 01-3.393.122 7.525 7.525 0 007.031 5.228C8.358 25.915 4.37 27.016.38 26.542a21.28 21.28 0 0011.54 3.378c13.97 0 21.873-11.8 21.384-22.393a15.14 15.14 0 003.76-3.882z" fill="#15182B"></path></svg>
                            </span> */}
                            <a href="https://x.com/mecollateral" target="_blank" rel="noopener noreferrer" style={{cursor: "pointer"}}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                    </svg>
                                </span>
                            </a> 
                            <a href="https://discord.gg/DHXPY4pzqv" target="_blank" rel="noopener noreferrer" style={{cursor: "pointer"}}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-discord" viewBox="0 0 16 16">
                                      <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </Col>
                    <Col md={4} className='mb-5' data-aos="fade-up" data-aos-delay="600">
                        <h4 className="heading">Locations</h4>

                        <h5 className='mb-4 font-normal'>North Carolina, USA</h5>
                        <h5 className="font-normal">BoonLay - Singapore</h5>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Contact;