import React from 'react';
import {Container} from 'react-bootstrap';
import {
    Link
  } from "react-router-dom";


function Footer() {
    return (
        <div className="footer text-center">
            <Container>
                <div className="footer-logo mb-3">
                    <Link to="/">MasterMind</Link>
                </div>

                <div className="mb-4 pb-3">
                    {/* <span className='btn btn-round btn-round-lg'><svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17"><path d="M4.306 1.88c0 .994-.8 1.8-1.786 1.8a1.793 1.793 0 01-1.786-1.8c0-.994.8-1.8 1.786-1.8s1.786.806 1.786 1.8zm.014 3.24H.72v11.52h3.6V5.12zm5.746 0H6.487v11.52h3.579v-6.048c0-3.362 4.341-3.636 4.341 0v6.048H18V9.346c0-5.673-6.422-5.464-7.934-2.67V5.12z" fill="#fff"></path></svg></span> */}
                    {/* <span className='btn btn-round ms-3 btn-round-lg'><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 15"><path d="M18 2.623a7.117 7.117 0 01-2.038.562 3.553 3.553 0 001.563-1.958 7.358 7.358 0 01-2.254.864A3.518 3.518 0 0012.686.96c-2.29 0-3.967 2.138-3.456 4.355a10.084 10.084 0 01-7.308-3.708 3.557 3.557 0 001.095 4.738 3.596 3.596 0 01-1.606-.447C1.375 7.541 2.55 9.075 4.255 9.42a3.5 3.5 0 01-1.598.058 3.545 3.545 0 003.312 2.462A7.102 7.102 0 01.72 13.41 10.023 10.023 0 006.156 15c6.58 0 10.303-5.558 10.073-10.548A7.131 7.131 0 0018 2.623z" fill="#fff"></path></svg></span> */}
                    <a href="https://x.com/mecollateral" target="_blank" rel="noopener noreferrer" style={{cursor: "pointer"}}><span style={{cursor: "pointer"}} className='btn btn-round ms-3 btn-round-lg'>
                        <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-twitter-x" viewBox="0 0 16 16">
                            <path style={{cursor: "pointer"}} d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                        </svg>
                    </span></a>
                </div>

                <ul className='list-unstyled d-flex pb-2 mb-4 align-items-center justify-content-center'>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/invest">INVEST</Link></li>
                    <li><Link to="/portfolio">PORTFOLIO</Link></li>
                    <li><Link to="/contact-us">CONTACT US</Link></li>
                </ul>

                <p>&copy; {new Date().getFullYear()} MasterMind Ventures . All rights reserved.</p>
            </Container>
        </div>
    );
}

export default Footer;